.integration_item{
  width: 200px;
  height: 200px;
  border: 1px solid lightgray;
  padding: 20px;
  opacity: 0.7;
  transition: 0.2s;
}

.integration_item:hover {
  transform: scale(1.2);
  opacity: 1;
}
